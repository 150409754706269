<template>
  <div class='cs-l1'>
    <ek-panel title='过街天桥'>
      <div class='box'>
        <div class='box1' style="margin-top: 10px;margin-bottom: 15px">
          <div class='box1-item' @click='preWarning'>
            <img :src="isWarning ? '/images/components/std/bgL-r.png' :'/images/components/std/bgL.png'" class='brighBG brighBG1'
                 :class="isWarning? 'waringClass': '' "
                 alt=''>
            <img :src="isWarning ? '/images/components/std/icon01-r.png':'/images/components/std/icon01.png'" alt=''
                 :class="isWarning? 'waringClass': '' " />
            <div class='box-item-rt1'  :class="isWarning? 'waringClass': '' ">
              <div class="title">当前人数</div>
              <div class='numBox1'>
                <span class='num' :class="isWarning? 'waringClass': '' ">{{ calTotalNum(totalNum).val }}</span
                ><span :class="isWarning? 'waringClass': '' ">{{ calTotalNum(totalNum).unit }}</span>
              </div>
            </div>
          </div>
          <div class='box1-item' :class="isWarning? 'waringClass': '' ">
            <img :src="isWarning ? '/images/components/std/bgL-r.png' :'/images/components/std/bgL.png'" class='brighBG brighBG1'
                 alt=''>
            <img :src="isWarning ? '/images/components/std/icon01-r.png':'/images/components/std/icon01.png'" alt='' />
            <div class='box-item-rt1'>
              <div class="title" >预警阈值</div>
              <div class='numBox1'>
                <span class='num' :class="isWarning? 'waringClass': '' ">{{ calTotalNum(alarmNum).val }}</span
                ><span :class="isWarning? 'waringClass': '' ">{{ calTotalNum(alarmNum).unit }}</span>
              </div>
            </div>
          </div>

        </div>
        <div class='box1 box2' >
          <div class='box1-item' v-for="(item,index) in switchStat" :key="index"  :class="isWarning? 'waringClass': '' ">
            <img :src="isWarning ? '/images/components/std/bg-r.png' :'/images/components/std/bg.png'" class='brighBG'
                 alt=''>
            <img :src="isWarning ? '/images/components/std/icon02-r.png':'/images/components/std/icon02.png'" alt='' />
            <div class='box-item-rt'  :class="isWarning? 'waringClass': '' ">
              <p style="color:white">摆闸状态-{{item.deviceName}}</p>
              <div class='numBox'>
                <span class='num' :class="isWarning? 'waringClass': '' "
                >{{ isWarning ? status : calSwitch(item.deviceSwitch) }}
                </span>
              </div>
            </div>
          </div>

        </div>
        <r2></r2>
        <r3></r3>
      </div>
    </ek-panel>

  </div>
</template>

<script>
import r2 from './r2'
import r3 from './r3'

export default {
  data() {
    return {
      alarmNum: 500000,
      status: '关闭',
      totalNum: 325000,
      predictionNum: 500000,
      isWarning: false,
      switchStat:[]
    }
  },
  filters:{
    filterSwith(value){
      if(value =='1'){
        return '开启'
      }else{
        return '关闭'
      }
    }
  },
  computed: {
    calTotalNum: function() {
      return function(val) {
        let unit = ' 人'
        if (Number(val) > 10000) {
          val = (val / 10000).toFixed(2)
          unit = ' 万人'
        }
        return {
          val: val,
          unit: unit
        }
      }
    },
    calSwitch:function(){
      return val=>{
        if(val =='1') return '开启'
        else return '关闭'

      }
    }
  },
  components: {
    r2,
    r3

  },
  mounted() {
    this.fetchData()
    this.$eventBus.$off('std-warn-dzstatus')
    setTimeout(()=>{
      this.$eventBus.$on('std-warn-dzstatus',res=>{
        console.log(res,'是否传参了')
        this.status = res ? '开启':'关闭'
        this.warnFlag = true
        // 改变数据的状态
        this.switchStat.map(item=>{
          item.deviceSwitch = res ? '1':'0'
          // console.log(item,'当前状态')
        })
      })
    },300)

  },
  watch: {
    // totalNum(val){
    //
    // }
  },
  methods: {
    fetchData() {
      this.$eventBus.$off('std-r2-data')
      this.$eventBus.$on('std-r2-data', resultData => {
        this.alarmNum = resultData.alarmNum
        this.totalNum = resultData.totalNum
        this.predictionNum = resultData.predictionNum
        this.status = resultData.switchStat[0].deviceSwitch === '1' ? '开启':'关闭'
        this.switchStat = resultData.switchStat
        this.reslist = resultData
      })
    },
    preWarning() {
      // 客流演示

      if (Number(this.totalNum) >= Number(this.alarmNum)) {
        console.log('添加演示')

      }
      this.isWarning = !this.isWarning

      this.$eventBus.$emit('std-r1-iswarning', {
        flag: this.isWarning,
        curNum: this.totalNum,
        alarmNum: this.alarmNum,
        switchStat:this.switchStat
      })
      this.$eventBus.$emit('std-r1-iswarning-l2', {
        flag: this.isWarning,
        curNum: this.totalNum,
        alarmNum: this.alarmNum,
        switchStat:this.switchStat
      })
      this.$eventBus.$emit('std-r1-iswarning-center', {
        flag: this.isWarning,
        curNum: this.totalNum,
        alarmNum: this.alarmNum,
        switchStat:this.switchStat
      })
      this.$eventBus.$emit('std-r1-iswarning-map', {
        flag: this.isWarning,
        curNum: this.totalNum,
        alarmNum: this.alarmNum,
        switchStat:this.switchStat
      })
      this.$eventBus.$emit('std-r1-iswarning-total', {
        flag: this.isWarning,
        curNum: this.totalNum,
        alarmNum: this.alarmNum,
        switchStat:this.switchStat
      })
      this.$eventBus.$emit('std-r1-iswarning-legend', {
        flag: this.isWarning,
        curNum: this.totalNum,
        alarmNum: this.alarmNum,
        switchStat:this.switchStat
      })

    }
  }
}
</script>

<style scoped lang='scss'>
.box {
  height: 1000px;
  @include flex(column);
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;

  .box1 {
    display: flex;
    //justify-content: space-between;

    .box1-item {
      width: 31%;
      display: flex;
      flex: 1;
      align-items: center;
      //background: rgba(239, 156, 0, 0.1);
      //background: url('/images/components/std/bg.png');
      background-size: 100% 100%;
      padding: 10px;
      box-sizing: border-box;
      .box-item-rt1{
        display: flex;
        flex-display: row;
        align-items: center;

        .title{
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          margin: 0 20px;
        }
        .numBox1 {
          color: rgba(239, 156, 0, 1);
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FEA600;
          .num {
            font-size: 40px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #EF9C00;
          }
        }
      }
      .brighBG {
        width: 220px;
        //width: 415px;
        height: 90px;
        position: absolute;
        margin-left: -10px;
        //float: left;
        //background: rgba(239, 156, 0, 0.1);
        //background: url('/images/components/std/bg.png');
        //background-size: 100% 100%;
        //padding: 10px;
        //box-sizing: border-box;
      }
      .brighBG1{
        width: 442px;

      }
      img {
        width: 70px;
        height: 70px;
        margin-right: 10px;
      }

      p {
        margin: 0;
      }

      .numBox {
        color: rgba(239, 156, 0, 1);
        font-size: 20px;
        margin-top: 6px;

        .num {
          font-size: 34px;
          font-weight: bold;
        }
      }
    }
  }
}






.waringClass {
  color: #E70000 !important;
  animation-name: fade-in;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;

  /* shorthand
  animation: fade-in 0.8s linear 0s infinite alternate none;*/
}
@keyframes fade-in {

  0% {
    opacity: 0;
  }
  50%{
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}



</style>
