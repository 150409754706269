<template>
  <div class="container">
    <div class="total">
      <ek-total :isConutTo="true" width="280px" />
    </div>
    <ek-legend />
    <warn-panel ref="warnPanel" style="position: absolute;top: 14%;left: 50%;transform: translateX(-50%)"
    :totalNum="totalNum" :alarmNum="alarmNum" :switchStatFlag="switchStatFlag"
    ></warn-panel>
    <!-- <div class="bottom-btn">
      <div class="btn-3d"></div>
    </div> -->
  </div>
</template>

<script>
import warnPanel from './components/stdWarnPanel'
export default {
  components:{
    warnPanel
  },
  data(){
    return{
      totalNum:0,
      alarmNum:0,
      dzStatus:'关闭',
      switchStatFlag:true
    }
  },
  mounted(){
    this.$eventBus.$off('std-r1-iswarning-center')
    setTimeout(()=>{
      this.$eventBus.$on('std-r1-iswarning-center',res=>{
        // const flag = res.flag
        this.totalNum = res.totalNum
        this.alarmNum = res.alarmNum
        this.switchStat = res.switchStatus
        this.switchStatFlag = res.switchStat[0].deviceSwitch === '1' ? true:false
   
        if(!res.flag){
          this.$refs.warnPanel.close()
        }else{
          setTimeout(()=>{
            this.$refs.warnPanel.open()
          },3500)
        }


      })
    },300)

  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
}
.total {
  margin-top: 10px;
}
.bottom-btn {
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 20px;
  .btn-3d {
    width: 200px;
    height: 200px;
    background-image: url('/images/components/std/3d.png');
    background-size: 100% 100%;
  }
}
</style>
