<template>
  <div class="box">
    <div class="title">实时监控画面</div>
    <div class="camListBox" @click="getCameraList">查看更多</div>
    <div class="box1">
      <div class="box1-item" v-for="(item,index) in eelist" :key="index" >
        <div class="box1-item-video">
          <iframe
            style="width:100%;height:100%"
            :src="item.url"
            align="middle"
            frameborder="0"
            allowfullscreen
            referrerPolicy="no-referrer"
          ></iframe>
        </div>
        <div
          class="pop-btn"
          @click="
            handleItem(item.url)
          "
        ></div>
        <div class="box1-item-des">
          <div class="num">0{{index+1}}</div>
          <div :title="item.name" class="name">{{item.name}}</div>
          <div class="address">天桥</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      eelist:[
        // {name:'G09-C',url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.109-1'},
        // {name:'G44-A',url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.44-1'},
        ]
    }
  },
  components: {},
  mounted() {
    let that = this

        
        if(window.eelist){
           that.eelist = Array.from(new Set(window.eelist));
        }else{
          this.$eventBus.$off('ov-std-deviceList2')
            
setTimeout(()=>{
        
            this.$eventBus.$on('ov-std-deviceList2', res => {
        console.log(res,'this is res')
          that.eelistTem =[]
            res.forEach(item => {
              let data = item.allData
              data.cameraUrl = data.cameraUrl.replace(/\n+$/, '')
              if(data.isRealTime =='1'){
                that.eelistTem.push({
                  name:data.deviceName,
                  url:data.cameraUrl
                })
              }
            });

             if(that.eelistTem.length == 2 ){
            that.eelist = Array.from(new Set(that.eelistTem));
            window.eelist = that.eelistTem
          }

        })

        },30)
        }
        
        
      
  },
  methods: {
    handleItem(url) {
      console.log(url,'urlsss')
      this.$eventBus.$emit('dev-afjk', {
        url,
        isFull: true
      })
    },
    getCameraList(){
        this.$eventBus.$emit('iw-bridge3D')
      // this.$eventBus.$emit('stdcam-panel',true)
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 1000px;
  @include flex(column);
  padding: 10px;
  box-sizing: border-box;
  .camListBox{
    position:absolute;
    right: 22px;
    width: 116px;
    height: 40px;
    background: #6F4800;
    opacity: 0.8;
    border-radius: 6px;
    border: 1px solid #EF9C00;
    text-align: center;
    line-height: 40px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
  .title {
    font-size: 26px;
    font-weight: bold;
    margin-top: 20px;
  }
  .box1 {
    display: flex;
    justify-content: space-between;
    .box1-item {
      position: relative;
      width: 49%;
      height: 320px;
      border: 1px solid rgba(129, 197, 200, 1);
      border-radius: 5px;
      margin-top: 10px;
      border-bottom: 1px solid rgba(129, 197, 200, 0.5);
      .pop-btn {
        position: absolute;
        top: 0;
        width: 100%;
        height: 280px;
        cursor: pointer;
      }
    }
    .box1-item-video {
      height: calc(100% - 40px);
      // background-color: #fff;
    }

    .box1-item-des {
      display: flex;
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-top: 1px solid rgba(129, 197, 200, 1);
      justify-content: space-between;
      padding-right: 10px;
      box-sizing: border-box;

      .num {
        width: 50px;
        text-align: center;
        background-image: linear-gradient(
          to right,
          rgba(129, 197, 200, 1),
          rgba(129, 197, 200, 0.1)
        );
        border-right: 1px solid rgba(129, 197, 200, 1);
      }
      .name {
        width: 300px;
        margin-left: 10px;
       white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      }
    }
  }
}
</style>
