<template>
  <div class="cs-l1">
    <ek-panel title="今日值班">
      <div class="table-box">
        <div class="table-list">
          <div class="table-item">
            <p class="f1">序号</p>
            <p class="f2">姓名</p>
            <p class="f2">部门</p>
            <p class="f2">联系方式</p>
          </div>
          <div class="table-body" >
            <swiper
              :options="swiperOptions"
              ref="orderSwiper"
            >
              <swiper-slide  v-for="(items,index) in list" :key="index"
                             @mouseenter="onEnterClick"
                             @mouseleave="onLeaveClick"
              >
                <div v-for="(item,index) in items" :key="index"  class="table-item">
                  <p class="f1">{{ index+1 }}</p>
                  <p class="f2">{{ item.name }}</p>
                  <p class="f2">{{item.dep}}</p>
                  <p class="f2">{{item.tel}}</p>
                </div>

              </swiper-slide>
            </swiper>

          </div>

        </div>
      </div>
    </ek-panel>
  </div>
</template>

<script>
// import { _axios } from '@/utils/request'
import {fetchData} from '@/api'
import { fetchFindByType } from '@/api'
export default {
  data() {
    return {
      swiperOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 900,
        loop: false
      },
      list:[]
    }
  },
  components: {},
  mounted() {
    this.fetchType()
    
  },
  methods: {
    async fetchType() {
      const { resultCode, resultData } = await fetchFindByType('department')
      if (resultCode === '200' && resultData) {
       this.listt = []
       
        resultData.map((item) => {
          this.listt.push({
            businId:item.businId,
            type:item.businName
          })
        })
        
      }
      console.log(this.listt,'ddd')  
      this.fetchData()
    },
    setType(val){
      return this.listt.find(item=>{
        return item.businId == val
      }).type
    },
    async fetchData() {
      const { resultCode, resultData } = await fetchData('duty_roster')
      if (resultCode === '200' && resultData && resultData.content.length !==0 ) {
          this.list = [] 
          resultData.content.forEach(item=>{
            this.list.push(
              {name:item.title,
              dep:this.setType(item.department),
              tel:item.phone
            },
            )
          })
      }
      else
      {
        this.list=[
          {name:"张三帅",dep:"安保科",tel:15423456786},
          {name:"黄博",dep:"物业科",tel:15889925566},
          {name:"胡格",dep:"安保科",tel:13355668899},
          {name:"李司司",dep:"环保科",tel:13955555000},

        ]
      }
      this.formatItemForData()
    },
    formatItemForData(){

      this.list = this.groupList(this.list,4)
    },
    onEnterClick() {
      this.swiper.autoplay.stop()
    },
    onLeaveClick() {
      this.swiper.autoplay.start()
    }
  }
}
</script>

<style scoped lang="scss">
.table-box {
  height: 290px;
  padding: 5px 15px;
  .table-list {
    .table-item {
      display: flex;
      //justify-content: space-between;
      padding: 0 10px;
      &:nth-child(odd) {
        background: rgba(32, 39, 56, 0.8);
      }
      &:nth-child(even) {
        background: rgba(32, 39, 56, 0.5);
      }
      p {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .w100 {
        width: 100px;
      }
      .w200 {
        width: 200px;
      }
      .f1{
        flex:1
      }
      .f2{
        flex:2
      }
    }
  }
  .table-body{
    height:230px;
    .swiper-container {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
