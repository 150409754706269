<template>
  <div class="cs-l1">
    <ek-panel title="预警事件">
      <div class="table-box">
        <div class="table-list">
          <div class="table-item">
            <p class="f1">序号</p>
            <p class="f3">报警类型</p>
            <p class="f2">设备/来源</p>
            <p class="f4">报警内容</p>
            <p class="f3">报警位置</p>
            <p class="f3">报警时间</p>
          </div>
          <div class="table-body">
            <div v-if="dzFlag" class="table-item waringClass">
              <p class="f1">1</p>
              <p class="f3">{{ dizOBJ.type }}</p>
              <p class="f2">{{ dizOBJ.dev }}</p>
              <p class="f4">{{ dizOBJ.content }}</p>
              <p class="f3">{{ dizOBJ.postion }}</p>
              <p class="f3">{{ dizOBJ.time }}</p>
            </div>
            <swiper :options="swiperOptions" ref="orderSwiper">
              <swiper-slide
                v-for="(items, index) in list"
                :key="index"
                @mouseenter="onEnterClick"
                @mouseleave="onLeaveClick"
              >
                <div
                  v-for="(item, index) in items"
                  :key="index"
                  class="table-item"
                >
                  <!-- <div v-for="(item,index) in items" :key="index"  class="table-item" :class="(dzFlag && index ==0)? 'warningClass': '' " > -->
                  <p class="f1">{{ dzFlag ? index + 2 : index + 1 }}</p>
                  <!-- <p class="f1">{{ index+1 }}</p> -->
                  <p class="f3">{{ item.type }}</p>
                  <p class="f2">{{ item.dev }}</p>
                  <p class="f4">{{ item.content }}</p>
                  <p class="f3">{{ item.postion }}</p>
                  <p class="f3">{{ item.time }}</p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </ek-panel>
  </div>
</template>

<script>
import { _axios } from '@/utils/request'
import { fetchFindByType } from '@/api'
import msgclient from '@/utils/rabbitMQ/msgclient.js'
export default {
  data() {
    return {
      swiperOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 900,
        loop: false
      },
      dzFlag: false,

      dizOBJ: {
        type: '设备',
        level: '1',
        dev: '客流监控',
        content: '客流量过大',
        postion: '过街天桥',
        time: '2023'
      },
      list: [],
      list2: [],
      sourceList: []
    }
  },
  mixins: [msgclient],

  computed: {
    swiper() {
      return this.$refs.orderSwiper.$swiper
    }
  },
  components: {},
  mounted() {
    this.getDeviceType()
    this.getAlarmType()
    this.$eventBus.$off('std-r1-iswarning-l2')
    setTimeout(() => {
      this.$eventBus.$on('std-r1-iswarning-l2', res => {
        this.dzFlag = res.flag
        // if(res.flag){
        let d = new Date()
        let year = d.getFullYear() //返回指定日期年份            2022    2022年
        let month = d.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        } //返回指定日期月份            6       7月
        let date = d.getDate()
        if (date < 10) {
          date = '0' + date
        }
        this.dizOBJ.time = year + '-' + month + '-' + date
        console.log(d.getMonth())
        if (this.dzFlag) {
          this.list = this.groupList(this.list2, 3)
        } else {
          this.list = this.groupList(this.list2, 4)
        }

        // }
        // this.totalNum = res.totalNum
        // this.alarmNum = res.alarmNum
      })
    }, 300)
    this.connectRabbitMQ()
  },
  methods: {
    listenerRabbitConnect(queueName) {
      this.client.subscribe(
        '/exchange/wdt.ioc.exchange',
        this.onRabbitMQresponseCallback,
        {
          'x-queue-name': queueName
        }
      )
    },
    /**
     * RabbitMQ  消息监听事件
     * @param msgData
     */
    listenerRabbitMQ(msgData) {
      console.log(msgData)
      if (!msgData.data) return
      if (
        msgData.messageType == 'alarm' &&
        msgData.data.alarmType == 'PASSENGER_FLOW_ALARM'
      ) {
        let item = msgData.data
        item.type = this.setType(item.alarmType)
        item.level = item.urgencyDegree
        item.dev = this.setDevType(item.deviceType) // item.sourceType
        item.content = item.alarmContent
        item.postion = item.alarmLocation
        item.time = item.alarmTime.split(' ')[0]
        this.sourceList.pop()
        this.sourceList.splice(0, 0, item)
        if (this.dzFlag) {
          this.list = this.groupList(this.sourceList, 3)
        } else {
          this.list = this.groupList(this.sourceList, 4)
        }
      }
    },
    async getAlarmType() {
      this.alarmType = []
      const { resultCode, resultData } = await fetchFindByType('alarm_type')
      if (resultCode === '200' && resultData) {
        resultData.forEach(item => {
          this.alarmType.push({
            alarmType: item.businId,
            alarmName: item.businName
          })
        })
      }

      this.fetchData()
    },
    async getDeviceType() {
      this.deviceType = []
      const { resultCode, resultData } = await fetchFindByType('device_type')
      if (resultCode === '200' && resultData) {
        resultData.forEach(item => {
          this.deviceType.push({
            type: item.businId,
            name: item.businName
          })
        })
      }
    },
    setType(val) {
      if (val) {
        return this.alarmType.find(item => {
          return item.alarmType === val
        }).alarmName
      } else {
        return ''
      }
    },
    setDevType(val) {
      if (val) {
        return this.deviceType.find(item => {
          return item.type === val
        }).name
      } else {
        return ''
      }
    },
    async fetchData() {
      const { resultCode, resultData } = await _axios.get(
        '/stat/alarmRecord/alarmListBySafety/'
      )
      if (resultCode === '200' && resultData && resultData.length !== 0) {
        //&& resultData.totalNum !==0

        // this.list = []
        this.list2 = []
        resultData.forEach(item => {
          this.list2.push({
            type: this.setType(item.alarmType),
            level: item.urgencyDegree,
            dev: item.sourceType,
            content: item.alarmContent,
            postion: item.alarmLocation,
            time: item.alarmTime.split(' ')[0]
          })
        })
      } else {
        this.list2 = [
          {
            type: '设备',
            level: '1',
            dev: '报警柱',
            content: '紧急报警',
            postion: '台东三路88号',
            time: '04/03'
          },
          {
            type: '舆情',
            level: '2',
            dev: '新浪微博',
            content: '环境差',
            postion: '满口香饭店',
            time: '04/03'
          },
          {
            type: '设备',
            level: '3',
            dev: '智能垃圾桶',
            content: '垃圾桶满溢',
            postion: '台东三路88号',
            time: '04/03'
          },
          {
            type: '监控',
            level: '3',
            dev: '监控',
            content: '打架斗殴',
            postion: '台东三路77号',
            time: '06/03'
          }
        ]
      }
      this.sourceList = this.list2
      this.formatItemForData()
    },
    formatItemForData() {
      this.list = this.groupList(this.list2, 4)
    },
    onEnterClick() {
      this.swiper.autoplay.stop()
    },
    onLeaveClick() {
      this.swiper.autoplay.start()
    }
  },
  beforeDestroy() {
    if (!this.client) return
    this.onRabbitMQDisconnect()
  }
}
</script>

<style scoped lang="scss">
.table-box {
  height: 290px;
  padding: 5px 15px;
  .table-list {
    height: 100%;
    .table-item {
      display: flex;
      //justify-content: space-between;
      //padding: 0 10px;
      &:nth-child(odd) {
        background: rgba(32, 39, 56, 0.8);
      }
      &:nth-child(even) {
        background: rgba(32, 39, 56, 0.5);
      }
      p {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .f1 {
        flex: 1;
      }
      .f2 {
        flex: 2;
      }
      .f3 {
        flex: 3;
      }
      .f4 {
        flex: 4;
      }
      .f5 {
        flex: 5;
      }
      .f6 {
        flex: 6;
      }
    }
  }
  .table-body {
    height: 230px;
    .swiper-container {
      height: 100%;
      width: 100%;
    }
  }
}
.waringClass {
  color: #e70000;
  font-size: 18px;
  font-weight: bold;
  animation-name: fade-in;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;

  /* shorthand
  animation: fade-in 0.8s linear 0s infinite alternate none;*/
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
</style>
