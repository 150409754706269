<template>
  <div class='std-warn-panel' v-if='visible' style="width:1300px;height: 900px;">
    <div class='box' >
      <div class='i-box'>
        <div class='t-close' @click='close'>
          <img src='/images/map/ov/br/desc-close.png' alt='' />
        </div>
      </div>
      <div class='title'>
        <div style='font-weight: bold'>客流预警</div>

        <div class='title-item'>
          <div><span>当前人数 </span> <span class='title-item-highlight' style="color:#E80000;font-weight: bold;">556 人</span></div>
          <div><span>客流阈值 </span> <span class='title-item-highlight'> {{ alarmNum }} 人</span></div>

        </div>

      </div>

      <div class='content' style="height:670px;margin-left: 25px;">
        <!--        添加测试-->
        <div v-for="(item,index) in cameraList" :key="index" class='videoContainer' style="width:390px;height: 310px">
          <div  class="iframe-box">
            <iframe
              :src="item.url"

              align="middle"
              frameborder="0"
              allowfullscreen
              referrerPolicy="no-referrer"
            ></iframe>
          </div>

            <div class='videoFooter'>
                <div class='videoName'>{{item.name}}</div>
                <div class="videoFull" @click='videoFull(item)'> </div>
          </div>

        </div>
      </div>
      <div class='footer'>
        <div>摆闸状态：{{ dzFlag ? '开启':'关闭' }}</div>
        <div style='display: flex;'>
          <div style='margin-right: 40px'>是否开启</div>
          <div style='display: flex;align-items: center;'>
            <div class='statusTxt' @click='dzIson(true)'  :class="dzFlag? '':'activeDZ' ">是</div>
            <div class='statusTxt' @click='dzIson(false)' :class="dzFlag? 'activeDZ':'' ">否</div></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { _axios } from '@/utils/request'
export default {
  data() {
    return {
      visible: false,
      selectCamIndex:-1,
      url: '',
      width: '1900px',
      dzFlag:false,
      cameraList: [
        // {
        //   url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.5-1',
        //   name:'天桥下西南角立柱球机-A  '
        // },  {
        //   url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.6-1',
        //   name:'天桥下西北角立柱球机-A'
        // }, {
        //   url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.7-1',
        //   name:'天桥下东南角立柱球机-A'
        // }, {
        //   url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.8-1',
        //   name:'天桥下东北角立柱球机-A '
        // },{
        //   url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.84',
        //   name:'天桥西上下扶梯处 '
        // }, {
        //   url:'http://172.16.10.23:10089/ShowVideo.html?para1=172.16.11.90',
        //   name:'天桥东上下扶梯处'
        // },
      ]
    }
  },
  props: {
    totalNum: {
      type: Number,
      default: 0
    },
    alarmNum: {
      type: Number,
      default: 0
    },
    switchStatFlag:{
      type:Boolean,
      default:true
    }

  },
  mounted() {
    let that = this
    this.dzFlag = this.switchStatFlag

     if(window.cameraList){
        that.cameraList =   window.cameraList
     }else{
        
     this.$eventBus.$off('ov-std-deviceList1')
setTimeout(()=>{
this.$eventBus.$on('ov-std-deviceList1', res => {
           this.cameraListTem=[]
            res.forEach(item => {
              let data = item.allData
              data.cameraUrl = data.cameraUrl.replace(/\n+$/, '')
              if(data.isAlarm == '1'){
                that.cameraListTem.push({
                  name:data.deviceName,
                  url:data.cameraUrl
                })
              }
            });
          if(that.cameraListTem.length == 6 ){
            that.cameraList = that.cameraListTem
            window.cameraList = that.cameraList
          }
        })
     },30)
     }
     
     
      
  },
  methods: {
    open() {
      // console.log(url)
      // this.url = url
      // this.width = width
      this.visible = true
    },
    close() {
      this.visible = false
    },

    dzIson(v){

this.$prompt('', '提示', {
        customClass: 'popup-conirm',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入密码',
        inputType: 'password'
      })
        .then(({ value }) => {
          console.log(value,v, 'this is password')
          this.isBZchange(v,value)
         
        })
        .catch(() => {})


    },
    isBZchange(v,password){
         
    let changeStatus ='1'
      if(!v){
          changeStatus ='0'
      }
      this.changeDevSwitch(v,changeStatus,password)
    },
    async changeDevSwitch(v,value,password){
      const { resultCode, resultData } = await _axios.post(
        'stat/deviceInfo/controlDeviceSwitch',
        {
          deviceType: "gate",
          deviceSwitch: value,
          password:password
        }
      )
  
      if (resultCode === '200' && resultData) {
        console.log(resultData,'摆闸状态改变')
           this.dzFlag = v
           this.$eventBus.$emit('std-warn-dzstatus',this.dzFlag)
        return resultData
      }
    },
    videoFull(e){
      console.log(e)
      this.$eventBus.$emit('dev-afjk', {
        url: e.url,
        isFull: false
      })
    
    }
  }
}
</script>

<style scoped lang='scss'>
.std-warn-panel {
  position: absolute;
  left: 0;
  top: 30%;
  //transform: translateX(-50%);
  pointer-events: all;

  @include flex();
  align-items: center;
  justify-content: center;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    // background: rgba($color: #000000, $alpha: 0.8);
  }

  .box {
    z-index: 11;
    width: 100%;
    height: 100%;
    @include flex();
    //width: 1154px;
    //height: 755px;
    background: #0B1413;
    border: 2px solid #E80000;
    opacity: 0.85;
    position: relative;

    .i-box {
      @include flex(column);
      width: 100%;
      font-size: 24px;
      padding: 30px;

      .t-close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        pointer-events: auto;
        z-index:10;
        img {
          width: 100%;
          height: 100%;
        }
      }

      //.iframe-box {
      //  width: 100%;
      //  height: 100%;
      //}
    }

    .title {
      padding-left: 33px;
      position: absolute;
      height: 100px;
      line-height: 50px;
      width: 100%;
      display: block;
      font-size: 28px;

      .title-item {
        @include flex(row);
        width: 40%;
        justify-content: space-between;

        .title-item-highlight {
          line-height: 30px;
          font-size: 35px;
          color: #40ffe2;
        }
      }

      //p{
      //
      //  height: 30px;
      //  margin-left: 30px;
      //  font-size: 25px;
      //}
    }

    .content {
      position: absolute;
      //background-color: #ff1818;
      width: 100%;
      //width: 1165px;
      //height: calc(100% - 210px);
      height:100%;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;
    }

    .footer {
      position: absolute;
      bottom: 24px;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      height: 70px;
      background: #0B1413;
      border: 2px solid #44300B;
      line-height: 70px;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      font-size: 25px;
    }
  }
}
.statusTxt{

  background-color: #fff;
  width: 60px;
  height: 40px;
  color: #000;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
  border-radius: 4px;
  cursor:pointer
}
.activeDZ{
  background: #828282;
  color:white;
}

</style>
