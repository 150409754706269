<template>
  <div class="std-l1">
    <ek-panel title="实时客流" @click="handleTitle">
      <div class="box">
        <div class="box1">
          <div class="item">
            <div class="name">当前客流</div>
            <div class="numBox">
              <span class="num">{{totalNum}}</span><span class="unit">人</span>
            </div>
          </div>
          <div class="item">
            <div class="name">预计下一时段客流</div>
            <div class="numBox">
              <span class="num">{{ predictionNum }}</span><span class="unit">人</span>
            </div>
          </div>
          <div class="item">
            <div class="name">客流状态</div>
            <div class="numBox" style="font-size:30px;font-weight: 800;">
              {{status}}
            </div>
          </div>
        </div>
        <div class="box2">
          <div class="tools">
            <div class="item-box">
              <!-- <ek-select :list="dropDwons.arae"
                         @click="handleArea"
                         :selectVal="formData.value"
              /> -->
            </div>
          </div>
          <ek-chart :options="options"
                    :animation="true"
                    :animationData="options.xAxis.data"
          />
        </div>
      </div>
    </ek-panel>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { _axios } from '@/utils/request'
import { fetchFindByType } from '@/api'
var popuUnit='单位(人)'
export default {
  data() {
    return {
      dropDwons: {
        arae: []
      },
      formData:{
        label:'',
        value:0
      },
      chartData: [],
      options: {
        title: {
          text:popuUnit,
          textStyle: {
            color: '#fff',
            fontSize: 18
          },
          left: '1.8%'
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter:params =>{
            let el =``
            params.forEach(item=>{
              el = `<div class="tooltip-box column center">
                      <span class="time">${item.name} 时</span>
                      <div class="text marginTop">
                        <span class="t">${item.seriesName}：</span>
                        <span class="v">${Number(item.value ).toFixed(0)}人</span>
                      </div>
                    </div>`
            })
            return el
          }
        },
        grid: {
          top: '16%',
          left: '10%',
          right: '5%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: function(val) {
              return val + '时'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: function(val) {
              return val
            }
          }
        },
        series: [
          {
            name: '客流量',
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#fff'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00FCFF' // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: '#00ff8472' // 100% 处的颜色
                },
                {
                  offset: 1,
                  color: '#142D3F' // 100% 处的颜色
                }
              ])
            },
            markPoint: {
              symbol: 'circle',
              symbolSize: 10,
              label: {
                show: false
              },
              data: [
                {
                  type: 'max',
                  itemStyle: {
                    color: '#EF9C00'
                  }
                },
                {
                  type: 'min',
                  itemStyle: {
                    color: '#00C0FF'
                  }
                },
                {
                  type: 'average',
                  itemStyle: {
                    color: '#fff'
                  }
                }
              ]
            },
            markLine: {
              symbol: 'none',
              label: {
                show: false
              },
              lineStyle: {
                width:2,
                color: '#EF003E',
                type: [3, 5]
              },
              data: [
                {
                  type: 'average'
                }
              ]
            },
            data: []
          }
        ]
      },
      alarmNum:500000,
      status:"正常",
      switchStat:null,
      totalNum:32511,
      predictionNum:522345,
      areaType:'10'
    }
  },
  computed:{

  },
  components: {},
  mounted() {
    this.getAreaType()
    this.fetchData()
    // this.$eventBus.$off('std-curArea')
    // this.$eventBus.$on('std-curArea', res=>{
    //   this.areaType = res.areaType
    //   this.formData.value =res.index
    //   this.fetchData()
    // })
  },

  methods: {
    async getAreaType(){
      const { resultCode, resultData } = await fetchFindByType('service_area')
      if (resultCode === '200' && resultData) {
        let list = []
        this.araeList = resultData
        resultData.map((item, i) => {
          item.label = item.businName
          item.value = i
          list.push(item)
        })
        this.dropDwons.arae = list
      }
    },
    async fetchData() {
      const { resultCode, resultData } = await _axios.post('/stat/passengerAnalysis/passengerFlowChart/', {
        "areaType": this.areaType
      })
      if (resultCode === '200' && resultData  ) {//&& resultData.totalNum !==0

        this.options.xAxis.data = resultData.date
        this.options.series[0].data =resultData.data
        this.alarmNum = resultData.alarmNum
        this.totalNum= resultData.totalNum
        this.predictionNum = resultData.predictionNum
        this.status = resultData.status
      }
      else
        {
        let dateList = []
        for (let i = 0; i < 24; i++) {
          dateList.push(i)
        }
        this.options.xAxis.data = dateList
        this.options.series[0].data =[
          502.84,
          205.97,
          332.79,
          281.55,
          398.35,
          214.02,
          502.84,
          205.97,
          332.79,
          281.55,
          398.35,
          214.02,
          502.84,
          205.97,
          332.79,
          281.55,
          398.35,
          214.02,
          502.84,
          205.97,
          332.79,
          281.55,
          398.35,
          214.02
        ]
      }

    },

    handleArea(value) {


      this.areaType = this.araeList[value].businId
      this.formData ={
        label:this.araeList[value].businName,
        value:value
      }
      this.fetchData()
    },

    handleTitle(index) {
      console.log(index)
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  display: flex;
  .box1 {
    width: 200px;
    background: #202738;
    margin: 10px;
    box-sizing: border-box;
    .item {
      border-bottom: 1px solid #4a546b;
      padding-top: 20px;
      box-sizing: border-box;
      margin-left: 10px;
      text-align: center;
      margin-right: 10px;
      padding-bottom: 10px;
      .numBox {
        font-size: 20px;
        color: #0af7bc;
        margin-top: 4px;
        .num {
          font-size: 36px;
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
}
.box2 {
  flex: 1;
  height: 290px;
  @include flex(column);
  .tools {
    @include flex(column);
  }
  .item-box {
    @include flex();
    padding: 0 20px 0 25px;
    align-items: center;
    justify-content: flex-end;
  }
  .item-box:first-child {
    margin-top: 15px;
  }
  .space-between {
    justify-content: space-between;
  }
  .flex-end {
    justify-content: flex-end;
    margin-top: 10px;
    // background-color: #00ff8472;
  }
}
.item {
  margin-left: 30px;
}
</style>
