import { render, staticRenderFns } from "./r3.vue?vue&type=template&id=08bcdbad&scoped=true&"
import script from "./r3.vue?vue&type=script&lang=js&"
export * from "./r3.vue?vue&type=script&lang=js&"
import style0 from "./r3.vue?vue&type=style&index=0&id=08bcdbad&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08bcdbad",
  null
  
)

export default component.exports