<template>
    <div class="std-r2">
        <div class="box">
            <div class="title">实时客流</div>
            <div class="tools">
                <!-- <div class="item-box space-between">
         
        </div> -->
                <div class="item-box flex-end">
                    <ek-item-info title="客流量："
                                  :value="String(totalNum)"
                                  style="position: absolute;left: 30px;margin-bottom: 10px;" />
                    <chart-legend />
                    <ek-select :list="dropDwons.holiday"
                               :defaultVal="formData.holidayVal"
                               width="100px"
                               @click="handleHoliday" />
                    <div class="items">
                        <ek-segements :index="formData.dateVal"
                                      :defaultSelect="formData.holidayVal ? false : true"
                                      @click="handleDate" />
                    </div>
                </div>
            </div>
            <ek-chart :options="options"
                      :animation="true"
                      :animationData="options.xAxis.data" />
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import chartLegend from './chartLegend'
import { _axios } from '@/utils/request'
import { holiday } from "@/api/other";
var popuUnit = '单位(人)', dateType = 'DAY'
export default {
    data() {
        return {
            dropDwons: {
                arae: [
                    { label: '全部区域', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 }
                ],
                holiday: [
                    { label: '节假日', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 }
                ]
            },
            chartData: [],
            options: {
                title: {
                    text: popuUnit,
                    textStyle: {
                        color: '#fff',
                        fontSize: 18
                    },
                    left: '1.8%'
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: params => {
                        let { holidayVal, dateVal } = this.formData
                        let unit = holidayVal ? '' : dateVal == 0 ? '时' : ''
                        let el = ''
                        params.forEach(item => {
                            let date = this.dayjs(item.name).format(
                                holidayVal
                                    ? 'YYYY/MM/DD'
                                    : dateVal == 0
                                        ? 'YYYY/MM/DD HH'
                                        : dateVal == 1
                                            ? 'YYYY/MM/DD'
                                            : 'YYYY/MM'
                            )
                            el = `<div class="tooltip-box column center">
                      <span class="time">${date + unit}</span>
                      <div class="text marginTop">
                        <span class="t">${item.seriesName}：</span>
                        <span class="v">${parseInt(item.value)}人</span>
                      </div>
                    </div>`
                        })
                        return el
                    }
                },
                grid: {
                    top: '16%',
                    left: '6.8%',
                    right: '3%',
                    bottom: '15%'
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#fff',
                        fontSize: 16,
                        formatter: function (val) {
                            // console.log(val,dateType)
                            let unit = '时'
                            if (dateType === 'DAY') {
                                unit = '时'
                                val = val.split(' ')[1]
                                return val + unit
                            } else if (dateType === 'MONTH') {
                                unit = '日'
                                let s = val.split('-')
                                val = s[1] + '-' + s[2]
                                return val
                            } else if (dateType === 'YEAR') {

                                return val
                            } else {
                                return val
                            }

                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#2973AC'
                        }
                    },
                    // 不显示刻度线
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#2973AC',
                            type: [3, 5]
                        }
                    },
                    data: []
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#2973AC',
                            type: [3, 5]
                        }
                    },
                    axisLabel: {
                        color: '#fff',
                        fontSize: 16,
                        // formatter: function(val) {
                        // return val / 10000
                        // }
                    }
                },
                series: [
                    {
                        name: '客流量',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        lineStyle: {
                            color: '#fff'
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(186, 155, 98, 1)' // 0% 处的颜色
                                },
                                {
                                    offset: 0.7,
                                    color: 'rgba(186, 155, 98, 0.8)' // 100% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(186, 155, 98, 0.2)' // 100% 处的颜色
                                }
                            ])
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 10,
                            label: {
                                show: false
                            },
                            data: [
                                {
                                    type: 'max',
                                    itemStyle: {
                                        color: '#EF9C00'
                                    }
                                },
                                {
                                    type: 'min',
                                    itemStyle: {
                                        color: '#00C0FF'
                                    }
                                },
                                {
                                    type: 'average',
                                    itemStyle: {
                                        color: '#fff'
                                    }
                                }
                            ]
                        },
                        markLine: {
                            symbol: 'none',
                            label: {
                                show: false
                            },
                            lineStyle: {
                                width: 2,
                                color: '#ff0346',
                                type: [3, 5]
                            },
                            data: [
                                {
                                    type: 'average'
                                }
                            ]
                        },
                        data: []
                    }
                ]
            },
            formData: {
                areaVal: '',
                holidayVal: 0,
                dateVal: 0
            },
            alarmNum: 5000008,
            status: "关闭",
            switchStat: null,
            totalNum: 325000,
            predictionNum: 500012,
            dateTypeList: ['DAY', 'MONTH', 'YEAR'],
            dateType: 'DAY',
            holiday: undefined,
            areaType: undefined
        }
    },
    components: {
        chartLegend
    },
    mounted() {
        this.fetchHoliday()
        this.fetchData()
    },
    methods: {
        async fetchHoliday() {
            this.holidayList = []
            const { resultCode, resultData } = await holiday()
            if (resultCode === '200' && resultData) {
                let list = [{ label: '节假日', value: 0 }]
                this.holidayList = resultData
                resultData.map((item, i) => {
                    item.label = item.name
                    item.value = i + 1
                    list.push(item)
                })
                this.dropDwons.holiday = list
            }
        },
        async fetchData() {
            const { resultCode, resultData } = await _axios.post('/stat/passengerAnalysis/passengerFlowChartByBridge/', {
                "dateType": this.dateType,//DAY, MONTH, YEAR, HOLIDAY
                "holiday": this.holiday,
                "areaType": this.areaType// || "106238462648356"
            })
            if (resultCode === '200' && resultData) {// && resultData.totalNum!==0

                this.options.xAxis.data = [...resultData.date]
                this.options.series[0].data = resultData.data
                this.totalNum = resultData.totalNum + ''
                this.$eventBus.$emit('std-r2-data', resultData)
                // console.log(resultData)
            } else {
                let dateList = []
                for (let i = 0; i < 24; i++) {
                    dateList.push(i)
                }
                this.options.series[0].data = [
                    502.84,
                    205.97,
                    332.79,
                    281.55,
                    398.35,
                    214.02,
                    502.84,
                    205.97,
                    205.97,
                    205.97,
                    398.35,
                    214.02,
                    502.84,
                    205.97,
                    332.79,
                    332.79,
                    332.79,
                    214.02,
                    502.84,
                    205.97,
                    332.79,
                    281.55,
                    398.35,
                    214.02,
                ]
                this.options.xAxis.data = dateList
            }
            this.formatNumber()

        },
        formatNumber() {

            if (Number(this.totalNum) > 10000) {
                this.totalNum = (this.totalNum / 10000).toFixed(2)
                popuUnit = '人'
            }

        },
        handleArea(value) {
            this.formData.areaVal = value
            // console.log(value)
        },
        handleHoliday(value) {
            this.formData.holidayVal = value
            this.holiday = this.holidayList[value - 1].code
            this.dateType = 'HOLIDAY'
            dateType = 'HOLIDAY'
            this.fetchData()

        },
        handleDate(value) {
            this.formData.dateVal = value
            this.formData.holidayVal = null
            this.holiday = undefined
            this.dateType = this.dateTypeList[value]
            dateType = this.dateType
            this.fetchData()

        },

        handleTitle(index) {
            console.log(index)
        }
    }
}
</script>

<style scoped lang="scss">
.box {
    //height: 460px;
    height: 360px;
    @include flex(column);
    .title {
        font-size: 26px;
        font-weight: bold;
        margin-top: 20px;
    }
    .tools {
        @include flex(column);
    }
    .item-box {
        @include flex();
        padding: 0 20px 0 25px;
        align-items: center;
    }
    .item-box:first-child {
        margin-top: 15px;
    }
    .space-between {
        justify-content: space-between;
    }
    .flex-end {
        justify-content: flex-end;
        margin-top: 10px;
        // background-color: #00ff8472;
    }
}
.item {
    margin-left: 30px;
}
</style>
